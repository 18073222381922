.about {
    margin-bottom: 10em;

    @include large {
        width: 90%;
        text-align: right;
        margin-bottom: 1em;
        min-width: 275px;
    }
    
    @include xlarge {
        width: 30%;
    }

    &-image {
        &-wrapper {
            text-align: center;

            @include large {
                text-align: right;
                width: 100%;
            }

            img {
                width: 50%;
                @include large {
                }
            }
        }
    }
}