.navigation {
    font-family: Tangerine;
    margin-bottom: 48px;
    
    @include large {
        margin-bottom: 2em;
    }
    
    a,
    a:hover,
    a:visited,
    &-home {
        color: $tertiary;
        font-size: 36px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-weight: normal;

        @include large {
            justify-content: flex-start;
        }

        .stars {
            position: relative;
            top: -7px;
            right: 16px;
            width: 44px;
            height: auto;
        }
    }
}