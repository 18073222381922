.featured {
    margin-bottom: 64px;

    @include large {
        display: flex;
        margin-bottom: 1em;
    }
    
    @include xlarge {
        width: 65%;
    }

    h1 {
        margin-bottom: 2em;
        text-align: center;
        font-size: 18px;

        @include large {
            text-align: left;
        }
    }

    a {
        @include large {
            text-align: left;
        }
    }

    .button {
        text-align: center;
    }

    &-book {
        display: block;
        text-align: center;
        margin-bottom: 18px;

        img {
            width: 50%;
            height: auto;
            -webkit-box-shadow: 0px 0px 17px 10px rgba(194,200,204,1);
            -moz-box-shadow: 0px 0px 17px 10px rgba(194,200,204,1);
            box-shadow: 0px 0px 17px 10px rgba(194,200,204,1);
        }
    }

    &-read-more {
        color: $secondary;
        font-size: 18px;
        text-decoration: underline;
        text-align: center;
        display: block;
        margin: 10px auto 2em;
        text-transform: uppercase;
    }

    .callout {
        font-size: 20px;
        color: $tertiary;
        font-weight: 600;
        
        @include large {
            margin-top: 36px;
        }
    }

    section {
        @include large {
            width: 50%;
        }

        &.image {
            @include large {
                min-width: 250px;
            }
        }

        &.description {
            @include large {
                min-width: 275px;
            }
        }
    }
}