$primary:  #AA6CB6; // background green
$secondary: #0D9798; // accent green
$tertiary: #AA6CB6; // purple

$black: #4D414E;
$white: #FFFFFF;

// size variables
$small: 480px;
$large: 768px;
$xlarge: 1068px;

// mixins & breakpoints
@mixin xlarge {
    @media (min-width: #{$xlarge}) {
        @content;
    }
}

@mixin large {
    @media (min-width: #{$large}) {
        @content;
    }
}

@mixin small {
    @media (min-width: #{$small}) {
        @content;
    }
}
