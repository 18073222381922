.socials {
    font-weight: 600;
    text-align: center;

    @include large {
        width: 300px;
        font-weight: 600;
        text-align: center;
        margin-top: 24px;
    }

    ul {
        display: flex;
        flex-direction: row;
        font-size: 30px;
        margin: 0 auto 1em;
        justify-content: space-evenly;
        width: 300px;

        li {
            font-size: 18px;
            margin: 0 auto;
        }
    }

    a {
        text-decoration: none;
    }
}