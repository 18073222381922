@import "./icons";
@import "./variables";
@import "./_socials.scss";
@import "./_navigation";
@import "./_about";
@import "./_featured";
@import "./content";



body, h1, h2, h3, h4, p, div, span, img, section, ul, li, address {
    list-style: none;
    margin: 0;
    padding: 0;
    border: none;
    font-weight: normal;
}

body {
    background: $primary;
    font-family: 'Source Sans 3';
    color: $black;
    font-size: 16px;
    font-weight: normal;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(170,108,182,1) 45%, rgba(70,40,124,1) 100%);
}

b, h1, h2, h3, h4 {
    font-weight: 600;
}

.wrapper {
    background: $white;
    margin: 0 8% 10%;
    padding: 24px 5% 10%;

    @include large {
        padding: 2% 5%;
    }
}

p {
    margin-bottom: 24px;
}

a, 
a:hover,
a:visited,
a:active {
    color: $tertiary;
    font-weight: 600;
}

.button {
    padding: 14px;
    border-radius: 60px;
    border: 1px solid $tertiary;
    color: $tertiary;
    text-decoration: none;
    font-weight: 600;
    margin: 20px 0;
    display: block;
    width: auto;
    text-align: center;
    transition: background-color 300ms ease-in-out;

    @include large {
        // padding: 1em;
    }

    &:hover,
    &:active {
        background-color: $tertiary;
        color: $white;
    }
}

.footer {
    text-align: left;
    // color: #AA6CB6;
    font-weight: bold;
}